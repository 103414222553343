<template>
  <default-layout>
    <section>
      <div class="form-inline">
        <span class="form-inner">
        <span>
          <span class="font-weight-bold">試験：</span>
          <input-select v-model="examTermId" @change="changeExamTerm" :null-option="examTerms.length == 0"
                        :options="examTerms"
                        :opt-to-value="x => x"
                        :opt-to-input-value="t => t.examTermId"
                        :opt-to-label="t => t.name"
                        :opt-is-selected="t => t.examTermId == examTermId"/>
          <span v-if="examTerms.length == 0"> 選択可能な試験がありません</span>
        </span>
        <span class="form-inner">
          <span class="font-weight-bold"> 教室：</span>
          <input-select v-model="siteId" @change="reloadExaminees" :null-option="sites.length == 0"
                        :options="sites"
                        :opt-to-value="x => x"
                        :opt-to-input-value="s => s.siteId"
                        :opt-to-label="s => s.code + ': ' + s.name"
                        :opt-is-selected="s => s.siteId == siteId"/>
          <span v-if="examTerms.length > 0 && sites.length == 0"> 選択可能な教室がありません</span>
          </span>
        </span>
      </div>
      <div class="form-inline pt-2" v-if="examTerm">
        <span class="form-inner">
          <span class="font-weight-bold">実施期間：</span> {{examTerm.startTime | datetime}} 〜 {{examTerm.endTime | datetime}}
        </span>
      </div>
      <div class="pt-2 text-center" v-if="examTerm && examTerm.showResults">
        <button @click="downloadSiteScoreReports" v-button:download>スコアレポートダウンロード（教室全員分）</button>
        <button v-if="!isBridge" @click="downloadSiteCertificates" v-button:download>合格証ダウンロード（教室全員分）</button>
      </div>
    </section>
    <section v-if="examTerm">
      <h2><span class="material-icons">face</span>受検者リスト</h2>
      <panel>
        <form class="form-inline" v-form>
          <div>
            <span class="font-weight-bold">受検級：</span>
            <input-radios v-model="examGradeCode" name="examGrade" :options="examGradeOptions" :checkoffEnabled="false"/>
          </div>
          <div class="mt-1">
            <span class="font-weight-bold">受検状況：</span>
            <input-radios v-model="state" name="state" :options="stateOptions" :checkoffEnabled="false"/>
          </div>
        </form>
      </panel>
      <div v-if="!examTerm.ended || !examTerm.marked">
        <div class="table-responsive">
          <div class="reference"><span class="icon">library_books</span><a href="/download/endpoint/exam/download_standard" target="_blank">受検級の目安</a></div>
          <table class="table table-bordered" v-table:list.hover v-cols="[9,12,11,10,16,6,9,7,10,10]">
            <thead>
            <tr>
              <th>マイページ<br>登録</th>
              <th>会員番号</th>
              <th>学年</th>
              <th>名前</th>
              <th>ローマ字名</th>
              <th>性別</th>
              <th>生年月日</th>
              <th>受検級</th>
              <th>試験開始</th>
              <th>試験終了</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="e in examinees" :key="e.examieeId" @click="!edit && editExaminee(e.examineeId)">
              <td class="text-center"><span v-if="e.userAccountId">済</span><span class="icon" v-else>remove</span></td>
              <td class="text-center">{{e.examineeNumber}}</td>
              <td class="text-center">{{e.schoolGrade.name}}</td>
              <td>{{e.familyName}} {{e.givenName}}</td>
              <td>
                <span v-if="e.familyNameEn || e.givenNameEn">{{e.familyNameEn}} {{e.givenNameEn}}</span>
                <span v-else>未登録</span>
              </td>
              <td class="text-center">{{e.sex.name}}</td>
              <td class="text-center">{{e.dateOfBirth | date('YYYY/MM/DD')}}</td>
              <td class="text-center" :class="{'exam-grade': edit}">
                <span v-if="edit && !e.startTime">
                  <input-select v-model="examGradeCodes[e.examineeId]" :null-option="true"
                                :options="examGrades"
                                :opt-to-value="x => x"
                                :opt-to-input-value="t => t.code"
                                :opt-to-label="t => t.name"
                                :opt-is-selected="t => t.code == e.examGradeCode"/>
                </span>
                <span v-else>
                  {{e.examGradeName || '未設定'}}
                </span>
              </td>
              <td class="text-center">{{e.startTime | timestamp}}</td>
              <td class="text-center">{{e.endTime | timestamp}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center p-2" v-if="!edit && !examTerm.settingDeadlinePassed && editableCount > 0">
          <button v-button:default @click="editGrades">受検級一括設定</button>
        </div>
        <div class="text-center p-2" v-if="edit">
          <div><!--このdivがないとボタン表示がおかしくなる-->
            <button v-button:cancel @click="edit = false"/>
            <button v-button:primary @click="updateExamGrades">保存する</button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="table-responsive">
          <table class="table table-bordered" v-table:list.hover v-cols="[18,15,13,10,13,10,10,10]">
            <thead>
            <tr>
              <th>会員番号</th>
              <th>学年</th>
              <th>名前</th>
              <th>受検級</th>
              <th>受検日</th>
              <th>合否</th>
              <th>スコアレポート</th>
              <th>合格証</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="e in examinees" :key="e.examieeId">
              <td class="text-center">{{e.examineeNumber}}</td>
              <td class="text-center">{{e.schoolGrade.name}}</td>
              <td>{{e.familyName}} {{e.givenName}}</td>
              <td class="text-center">{{e.examGradeName || '未設定'}}</td>
              <td class="text-center">
                <span v-if="e.startTime">{{e.startTime | date}}</span>
                <span v-else>未受検</span>
              </td>
              <td class="text-center">{{examTerm.showResults && e.passingGrade ? e.passingGrade.name : "−"}}</td>
              <td class="text-center"><button v-button:download @click="downloadScoreReport(e.examineeId)" v-if="examTerm.showResults"/></td>
              <td v-if="!isBridge" class="text-center"><button v-button:download @click="downloadCertificate(e.examineeId)" v-if="examTerm.showResults && e.passingGrade && e.passingGrade.id > 0"/></td>
              <td v-else class="text-center">−</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <modal ref="examineeModal" title="受検者情報" v-if="examTerm">
      <examinee-modal :siteId="siteId" :examineeId="examineeId" :examTerm="examTerm" @close="closeExamineeModal"></examinee-modal>
    </modal>
  </default-layout>
</template>

<script>
import {Service} from "../_resource";
import {mapState} from "vuex";
import Modal from "@/calico-vue/ui/components/Modal";
import ExamineeModal from "./ExamineeModal";
import {announce} from "@/calico-vue/service";

export default {
  components: {Modal, ExamineeModal},
  data() {
    return {
      examineeId: null,
      examTermId: null,
      siteId: null,
      examGradeCode: "all",
      state: false,
      sites: [],
      allExaminees: [],
      examGrades: [],
      edit: false,
      examGradeCodes: {},
      isBridge: false,
    };
  },
  computed: {
    ...mapState([
      "examTerms",
      "currentExamTerm"
    ]),
    examinees() {
      return this.allExaminees
          .filter(e => this.examGradeCode === "all"
              || this.examGradeCode === e.examGradeCode
              || (this.examGradeCode === "0" && e.examGradeCode === null))
          .filter(e => this.state === false
              || (this.state === 1 && !e.startTime)
              || (this.state === 2 && e.startTime && !e.endTime)
              || (this.state === 3 && e.startTime && e.endTime));
    },
    examTerm() {
      return this.examTerms.find(t => t.examTermId == this.examTermId);
    },
    stateOptions() {
      return (this.examTerm && this.examTerm.marked)
          ? [
            {id: false, name: "すべて"},
            {id: 1, name: "未受検"},
            {id: 3, name: "受検済"}
          ]
          : [
            {id: false, name: "すべて"},
            {id: 1, name: "未受検"},
            {id: 2, name: "受検中"},
            {id: 3, name: "受検済"}
          ];
    },
    examGradeOptions() {
      const options = [];
      options.push({ id: "all", name: "すべて" });
      this.examGrades.forEach(g => options.push({ id: g.code, name: g.name }));
      options.push({ id: "0", name: "未設定"});
      return options;
    },
    editableCount() {
      return this.examinees.filter(e => !e.startTime).length;
    }
  },
  mounted() {
    Service.getIsBridge().then(isBridge => {
      this.isBridge = isBridge;
      Service.getGrades().then(grades => {
        if (this.isBridge){
          this.examGrades = grades;
        } else {
          this.examGrades = grades.filter(g => g.code != '1');
        }
      });
    });
    Service.getExamTerms().then(examTerms => {
      this.$store.commit("setExamTerms", examTerms);
      this.examTermId = examTerms[0]?.examTermId;
      this.changeExamTerm();
    })
  },
  methods: {
    changeExamTerm() {
      const siteId = this.siteId;
      this.sites = [];
      this.allExaminees = [];
      if (!this.examTermId) return;
      Service.getSites(this.examTermId).then(sites => {
        this.sites = sites;
        if (!sites.some(site => site.siteId === siteId)) {
          this.siteId = sites.length === 0 ? null : sites[0].siteId;
        }
        this.reloadExaminees();
      })
    },
    reloadExaminees() {
      this.allExaminees = [];
      if (!this.siteId) return;
      if (this.examTerm.marked && this.state == 2) this.state = false;
      return Service.getExaminees(this.siteId, this.examTermId).then(data => {
        this.allExaminees = data.examinees;
      });
    },
    editExaminee(examineeId) {
      this.examineeId = examineeId;
      this.$refs.examineeModal.open();
    },
    closeExamineeModal(reload) {
      if (reload) this.reloadExaminees();
      this.$refs.examineeModal.close();
    },
    updateExamGrades() {
      Service.updateExamGrades(this.siteId, this.examGradeCodes).then(() => {
        announce.saved();
        this.reloadExaminees().then(() => this.edit = false);
      });
    },
    editGrades() {
      this.examGradeCodes = {};
      this.examinees
          .filter(e => !e.startTime)
          .forEach(e => this.examGradeCodes[e.examineeId] = e.examGradeCode);
      this.edit = true;
    },
    downloadSiteScoreReports() {
      window.open("/download/endpoint/exam/download_site_score_reports" +
          "?examTermId=" + this.examTermId + "&siteId=" + this.siteId, "_blank");
    },
    downloadSiteCertificates() {
      window.open("/download/endpoint/exam/download_site_certificates" +
          "?examTermId=" + this.examTermId + "&siteId=" + this.siteId, "_blank");
    },
    downloadScoreReport(examineeId) {
      window.open("/download/endpoint/exam/download_score_report" +
          "?examTermId=" + this.examTermId + "&siteId=" + this.siteId + "&examineeId=" + examineeId,
          "_blank");
    },
    downloadCertificate(examineeId) {
      window.open("/download/endpoint/exam/download_certificate" +
          "?examTermId=" + this.examTermId + "&siteId=" + this.siteId + "&examineeId=" + examineeId,
          "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
td.exam-grade {
  min-width: 11rem;
}
.reference {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 0 10px 3px 0;

  .icon {
    color: #1e476b;
    padding: 0;
    font-size: 2rem;
  }
  a {
    color: #1e476b;
  }
}
</style>
